<!-- eslint-disable vue/no-v-html -->
<template>
	<b-modal
		id="inventory-changes-detail"
		ref="inventoryChangesDetail"
		header-class="mx-auto w-100 bg-dark text-white"
		:hide-footer="!isPendingTransfer"
		scrollable
		centered>
		<template v-slot:modal-header>
			<h5
				v-if="action.length"
				class="modal-title text-white">
				{{ translate(action) }}
			</h5>
			<button
				type="button"
				aria-label="Close"
				class="close text-white"
				@click="close">
				×
			</button>
		</template>
		<template v-slot:modal-footer>
			<b-button
				variant="secondary"
				class="text-nowrap mr-2"
				:disabled="actionLoading || loading || !$can('inventory', 'update')"
				@click="cancelTransfer">
				<i
					v-if="actionLoading && buttonLoading === 'cancel'"
					class="fas fa-spinner fa-spin" />
				{{ translate('cancel_transfer') }}
			</b-button>
			<b-button
				variant="primary"
				class="text-nowrap"
				:disabled="actionLoading || loading || !$can('inventory', 'update')"
				@click="completeTransfer">
				<i
					v-if="actionLoading && buttonLoading === 'complete'"
					class="fas fa-spinner fa-spin" />
				{{ translate('mark_as_complete') }}
			</b-button>
		</template>
		<template v-if="!loading && changes.length > 0">
			<div v-if="action === 'transfer_stock'">
				<div v-if="isCancelled">
					<b-alert
						variant="danger"
						show>
						<span v-html="translate('this_transfer_cancelled', { modifier: `${modifier.username} (${modifier.first_name} ${modifier.last_name})` })" />
					</b-alert>
				</div>
				<div v-else-if="isCompleted">
					<b-alert
						variant="green"
						show>
						<span v-html="translate('transfer_completed', { modifier: `${modifier.username} (${modifier.first_name} ${modifier.last_name})` })" />
					</b-alert>
				</div>
				<div v-else-if="!isCompleted && $route.name !== 'InTransitInventory'">
					<b-alert
						variant="warning"
						show>
						<span v-html="translate('transfer_pending')" />
					</b-alert>
				</div>
			</div>
			<div
				style="padding: 10px 20px; background-color: #f3f3f3; border-radius: 4px; margin-bottom: 20px">
				<b>Notes:</b> {{ notes }}
			</div>
			<div
				v-for="(change, index) in changes"
				:key="index">
				<b-row>
					<b-col
						cols="auto"
						class="d-flex justify-content-center align-items-center">
						<img
							:src="change.product.image"
							:alt="change.product.name"
							width="50px"
							class="img-fluid">
					</b-col>
					<b-col class="d-flex align-items-center">
						{{ change.product.name }}<br>
						{{ change.warehouse.name }}
						<template v-if="action === 'transfer_stock'">
							&rightarrow; {{ change.to_warehouse.name }}
						</template>
						<br>
					</b-col>
					<b-col
						cols="auto"
						class="d-flex justify-content-end align-items-center">
						<template v-if="Object.hasOwn(change, 'qty')">
							<div class="d-flex flex-column">
								<span
									:style="{
										color: getQtyColor(change),
										fontWeight: 'bold',
										fontSize: '20px',
									}">
									{{ getQtySign(change) }}{{ change.qty }}
								</span>
								<small
									style="font-size: 10px; text-align: right"
									class="text-muted">
									{{ translate('items') }}
								</small>
							</div>
						</template>
						<template v-if="Object.hasOwn(change, 'status')">
							<div style="font-weight: bold;">
								<del class="text-muted">
									{{ translate(change.status.previous) }}
								</del>
								&rarr;
								{{ translate(change.status.new) }}
							</div>
						</template>
					</b-col>
				</b-row>
				<hr v-if="!Object.hasOwn(change, 'status') && index < changes.length - 1">
			</div>
		</template>
		<div v-if="changes.length === 0 && !loading">
			{{ translate('data_not_found') }}
		</div>
		<is-loading
			:loading="loading"
			:loading-label="translate('loading')" />
	</b-modal>
</template>

<script>
import Inventory from '@/util/Inventory';
import { Inventory as InventoryMessages, InventoryChanges } from '@/translations';

export default {
	name: 'InventoryChangesDetail',
	messages: [InventoryMessages, InventoryChanges],
	props: {
		id: {
			type: [String, Number],
			required: true,
		},
		isPendingTransfer: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			inventory: new Inventory(),
			transferAction: new Inventory(),
			alert: new this.$Alert(),
			buttonLoading: '',
		};
	},
	computed: {
		changes() {
			try {
				return this.inventory.data2.response.data.products;
			} catch (error) {
				return [];
			}
		},
		notes() {
			try {
				return this.inventory.data2.response.data.notes;
			} catch (error) {
				return '';
			}
		},
		action() {
			try {
				return this.inventory.data2.response.data.action;
			} catch (error) {
				return '';
			}
		},
		isCancelled() {
			try {
				return this.inventory.data2.response.data.is_cancelled;
			} catch (error) {
				return false;
			}
		},
		isCompleted() {
			try {
				return this.inventory.data2.response.data.is_completed;
			} catch (error) {
				return false;
			}
		},
		modifier() {
			try {
				return this.inventory.data2.response.data.modifier;
			} catch (error) {
				return {
					username: '',
					first_name: '',
					last_name: '',
				};
			}
		},
		loading() {
			try {
				return this.inventory.data2.loading;
			} catch (error) {
				return false;
			}
		},
		actionLoading() {
			try {
				return this.transferAction.data.loading;
			} catch (error) {
				return false;
			}
		},
	},
	watch: {
		id: {
			handler(id) {
				if (id) {
					this.inventory.changesDetail(id);
				}
			},
			immediate: true,
		},
	},
	methods: {
		close() {
			this.$refs.inventoryChangesDetail.hide();
		},
		show() {
			this.$refs.inventoryChangesDetail.show();
		},
		completeTransfer() {
			const trans = {
				title: this.translate('mark_as_complete'),
				label: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
				message: this.translate('mark_as_complete_text'),
			};

			this.close();
			this.alert.confirmationWithPassword(trans.title, trans.label, options).then((password) => {
				this.show();
				const payload = {
					password: password.value,
				};
				this.buttonLoading = 'complete';
				this.transferAction.completeStockTransfer(this.id, payload).then(() => {
					this.close();
					this.alert.toast('success', this.translate('stock_updated'));
					this.$emit('completed');
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.transferAction.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {
				this.show();
			});
		},
		cancelTransfer() {
			const trans = {
				title: this.translate('cancel_transfer'),
				label: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
				message: this.translate('cancel_transfer_text'),
			};

			this.close();
			this.alert.confirmationWithPassword(trans.title, trans.label, options).then((password) => {
				this.show();
				const payload = {
					password: password.value,
				};
				this.buttonLoading = 'cancel';
				this.transferAction.cancelStockTransfer(this.id, payload).then(() => {
					this.close();
					this.alert.toast('success', this.translate('transfer_cancelled'));
					this.$emit('completed');
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.transferAction.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {
				this.show();
			});
		},
		getQtyColor(change) {
			if (this.action === 'transfer_stock') {
				return 'var(--blue)';
			}
			return change.operation === 'increment' ? 'var(--green)' : 'var(--red)';
		},
		getQtySign(change) {
			if (this.action === 'transfer_stock') {
				return '→';
			}
			return change.operation === 'increment' ? '+' : '-';
		},
	},
};
</script>

<style scoped>

</style>
