<template>
	<div class="table-responsive">
		<table class="table table-striped">
			<thead>
				<tr>
					<th
						v-if="$route.name === 'InventoryChanges'"
						class="border-top-0 pointer"
						@click="sortByField('action')">
						{{ translate('action') }}
						<sort field="action" />
					</th>
					<th
						v-if="$route.name === 'InTransitInventory'"
						class="border-top-0">
						{{ translate('from') }}
					</th>
					<th
						v-if="$route.name === 'InTransitInventory'"
						class="border-top-0">
						{{ translate('to') }}
					</th>
					<th
						class="border-top-0 pointer"
						@click="sortByField('performed_by')">
						{{ translate('performed_by') }}
						<sort field="performed_by" />
					</th>
					<th
						class="border-top-0 reason">
						{{ translate('notes') }}
					</th>
					<th
						class="border-top-0 pointer"
						@click="sortByField('date_time')">
						{{ translate('date_time') }}
						<sort field="date_time" />
					</th>
					<th
						class="border-top-0">
						{{ translate('options') }}
					</th>
				</tr>
			</thead>
			<tbody v-if="!loading && hasData">
				<tr
					v-for="change in changes"
					:key="change.id">
					<td v-if="$route.name === 'InventoryChanges'">
						{{ translate(change.attributes.code_name) }}
					</td>
					<td v-if="$route.name === 'InTransitInventory'">
						{{ getFromWarehouse(change) }}
					</td>
					<td v-if="$route.name === 'InTransitInventory'">
						{{ getToWarehouse(change) }}
					</td>
					<td>{{ change.attributes.causer.first_name }} {{ change.attributes.causer.last_name }}</td>
					<td class="reason">
						{{ change.attributes.notes }}
					</td>
					<td>{{ $moment(change.attributes.created_at.date).format(dateFormat) }}</td>
					<td>
						<b-button
							v-b-modal="'inventory-changes-detail'"
							v-b-tooltip.hover.html.left
							variant="secondary"
							:title="translate('view_thing', {name: translate('detail')})"
							@click="$emit('currentDetail', change.id)">
							<i
								class="fa fa-eye"
								aria-hidden="true" />
						</b-button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import sort from '@/components/SortArrow/index.vue';
import { YMDHMS_FORMAT } from '@/settings/Dates';
import { InventoryChanges, Tooltip } from '@/translations';

export default {
	name: 'InventoryChangesTable',
	messages: [InventoryChanges, Tooltip],
	components: {
		sort,
	},
	props: {
		changes: {
			type: Array,
			required: true,
		},
		loading: {
			type: Boolean,
			required: true,
		},
		hasData: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			dateFormat: YMDHMS_FORMAT,
		};
	},
	methods: {
		getFromWarehouse(change) {
			return change.attributes.from_warehouse.title;
		},
		getToWarehouse(change) {
			return change.attributes.to_warehouse.title;
		},
	},
};
</script>

<style scoped>
.reason {
	max-width: 250px;
	white-space: pre-line;
}
</style>
